<template>
	<div>
		<s-crud
			:filter="filter"
			:config="config"
			add
			
			remove
			title="Transbordos"
			@save="save($event)"
			@EditEvent="EditEvent($event)"
			@addEvent="addEvent($event)"
			@rowSelected="rowSelected($event)"
		>
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col cols="3" lg="3" md="3" sm="12">
							<s-text
								label="Guia de Cosecha"
								v-model="props.item.TftNumberGuideHarvest"
							></s-text>
						</v-col>
						<v-col cols="3" lg="3" md="3" sm="12">
							<s-text
								label="Guia de Remisión"
								v-model="props.item.TftNumberGuideReferral"
							></s-text>
						</v-col>
						<v-col cols="3" lg="3" md="3" sm="12">
							<s-select-generic
								label="Zona"
								itemtext="ZonName"
								itemvalue="ZonID"
								id="ZonName"
								full
								autocomplete
								:config="configZon"
								v-model="props.item.ZonID"
							>
							</s-select-generic>
						</v-col>
						<v-col cols="3" lg="3" md="3" sm="12">
							<s-select-transport
								label="Placa transbordada"
								full
								return-object
								v-model="objVehicleTransfer"
								@input="input($event)"
							></s-select-transport>
						</v-col>
						
					</v-row>
					<v-row>
						<v-col cols="3" lg="3" md="3" sm="12">
							<s-select-transport
								label="Placa a transbordar"
								full
								return-object
								v-model="objVehicleToTransfer"
							></s-select-transport>
						</v-col>
						<v-col cols="3" lg="3" md="3" sm="12">
							<s-text
								number
								label="Cantidad Jabas"
								v-model="TftQuantityJaba"
							></s-text>
						</v-col>
						<v-col cols="6" lg="6" md="6" sm="12">
							<s-textarea 
								label="Observaciones"
								v-model="props.item.TftObservation"
							></s-textarea>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:accion="{ row }">
				<v-btn
					x-small
					color="primary"
					outlined
					@click="calculatePayment(row)"
				>
					<i class="fas fa-file-invoice-dollar"></i>
				</v-btn>
			</template>
		</s-crud>

	</div>
</template>

<script>
	import _sWeighedByBatch from "@/services/FreshProduction/WeighedByBatchService";
	import _sTransferTruckService from "@/services/Technicalassistance/TransferTruckService"
	import SSelectTransport from "@/components/HarvestSchedule/SSelectTransport";
	import _sTransportPriceService from "@/services/Technicalassistance/TransportPriceService";
	import _sTransportPaymentService from "@/services/Technicalassistance/TransportPaymentService";

	export default {
		components: {
			SSelectTransport,
		},
		
		data() {
			return {
				TftQuantityJaba: 0,
				itemsNumberGuide: [],
				filter: {},
				config: {
					model: {
						TftID: "ID",
						accion: "String",
						TrcDateReception: "date"
					},
					service: _sTransferTruckService,
					headers: [
						{text: "ID", value: "TftID"},
						{text: "Acción", value: "accion"},
						{text: "Mes", value: "TrcMounth"},
						{text: "Fec. Recepción", value: "TrcDateReception"},
						{text: "Carga", value: "TypeCosteName"},
						{text: "Tipo Cultivo", value: "TypeCropName"},
						{text: "Zona", value: "ZonName"},
						{text: "Vehiculo Malogrado", value: "VehPlateTransfer"},
						{text: "Vehiculo de Traspaso", value: "VehPlateToTransfer"},
						{text: "N° Jabas", value: "TftQuantityJaba"},
					]
				},
				objVehicleTransfer: null,
				objVehicleToTransfer: null,
				configZon: {},
				isEdit: false,
				itemSelected: null,
			}
		},

		methods: {
			calculatePayment(row)
			{
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value){
						
						if(row.RcfID == null){
							this.$fun.alert("El transporte aún no ha sido recepcionado", "error");
							return;
						}
						let sum = 0
						let exist = false
						let may = 0;
						let selected = [this.itemSelected];

						selected.forEach(element => {
							if(element.TrcDistance > may){
								may = element.TrcDistance;
							}
							sum += element.TrcJabasHarvested
							element.TrcID = 0,
							element.SecStatus = 1
							element.UsrCreateID = this.$fun.getUserID();
							if(element.TrcJabasHarvestedAditional == -1){
								exist = true
								this.$fun.alert("Asigne tipo de Vehiculo a la Placa: " + element.TrcPlate, "warning")
							}
							
						});

						if(exist == false){
							let item = {
								PymID: 0,
								PymHigherDistance: may,
								PymJabasHarvested: sum,
								SecStatus: 1,
								UsrCreateID: this.$fun.getUserID(), 
								Detail: selected
							}

							if(item.Detail.length == 0) {
								this.$fun.alert("No existen registros", "warning")
								return;
							}
							console.log("Guardar",item);

							_sTransportPaymentService
							.savePayment(item, this.$fun.getUserID())
							.then((r) => {
								if(r.status == 200){
									this.$fun.alert("Guardado Correctamente", "success");
									this.$refs.gridTransportPayment.refresh();
								}
								
							});
						}
					}
				})
			},

			input(item){
				if(this.isEdit){
					this.TftQuantityJaba = this.itemSelected.TftQuantityJaba;
				}else{
					this.TftQuantityJaba = this.objVehicleTransfer.HvsQuantity;
				}
			},

			rowSelected(item)
			{
				if(item.length > 0){			
					this.itemSelected = item[0];
				}
				console.log("this.itemSelected ", this.itemSelected);
			},

			EditEvent()
			{
				this.objVehicleTransfer = null;
				this.isEdit = true;
				this.objVehicleTransfer.HvsQuantity = this.itemSelected.TftQuantityJaba;

				this.objVehicleTransfer.VehID = this.itemSelected.VehIDTransfer;
				this.objVehicleTransfer.VehPlate = this.itemSelected.VehPlateTransfer;

				this.objVehicleToTransfer.VehID = this.itemSelected.VehIDToTransfer;
				this.objVehicleToTransfer.VehPlate = this.itemSelected.VehPlateToTransfer;
				
				this.TftQuantityJaba = this.itemSelected.TftQuantityJaba;
			},

			addEvent()
			{
				
				this.isEdit = false;
				this.TftQuantityJaba = this.objVehicleTransfer.HvsQuantity;	
				
			},

			save(item)
			{
				
				if(this.objVehicleTransfer.VehID == this.objVehicleToTransfer.VehID){
					this.$fun.alert("Debe seleccionar diferentes vehiculos", "warning");
					return;
				}
				if(item.TftQuantityJaba == "" || item.TftQuantityJaba == 0){
					this.$fun.alert("Ingrese cantidad de jabas", "warning");
					return;
				}
				item.VehIDTransfer = this.objVehicleTransfer.VehID;
				item.VehPlateTransfer = this.objVehicleTransfer.VehPlate;
				item.TftDriverTransfer = this.objVehicleTransfer.Conductor;
				item.VehIDToTransfer = this.objVehicleToTransfer.VehID;
				item.VehPlateToTransfer = this.objVehicleToTransfer.VehPlate;
				item.TftDriverToTransfer = this.objVehicleToTransfer.Conductor;
				item.TftQuantityJaba = this.TftQuantityJaba;
				
				item.save();
			},

			getZon()
			{
				this.configZon = {
					url: _sTransportPriceService.GetZon(),
					title: this.label,
					params: { search: "", requestID: this.$fun.getUserID() },
				};
			},
		},

		created () {
			this.getZon();
		},
	}
</script>